import { Injectable, InjectionToken, Injector } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { DslEnvService, EVENT_PROCESSOR, EventContext, FEATURE_INIT_PROVIDER, LAZY_DSL_VALUES_PROVIDER, Logger } from '@frontend/vanilla/core';

/**
 * @experimental
 */
@Injectable({
    providedIn: 'root',
})
export class ModuleService {
    constructor(private dslEnvService: DslEnvService, private log: Logger) {}

    getProviders<T>(injector: Injector, token: InjectionToken<T>, notFoundValue?: T[]): T[] {
        try {
            return injector.get<T[]>(token, notFoundValue);
        } catch (e) {
            this.log.info('getProviders<T> failed for injector with token', injector, token, e);
            return [];
        }
    }

    getProvider<T>(injector: Injector, token: InjectionToken<T>, notFoundValue?: T): T | null {
        try {
            return injector.get(token, notFoundValue);
        } catch (e) {
            this.log.info('getProvider failed with token', injector, token, e);
            return null;
        }
    }

    runBootstrappers(injector: Injector) {
        try {
            const providers = this.getProviders(injector, FEATURE_INIT_PROVIDER, []);

            for (const provider of providers) {
                provider.onFeatureInit();
            }
        } catch (e) {
            this.log.error('runBootstrappers failed for injector', injector, e);
        }
    }

    runEventProcessors(injector: Injector, context: EventContext) {
        try {
            const providers = this.getProviders(injector, EVENT_PROCESSOR, []);
            for (const provider of providers) {
                provider.process(context);
            }
        } catch (e) {
            this.log.error('runEventHandlers failed with context', injector, context, e);
        }
    }

    runDslProviders(injector: Injector) {
        try {
            const dslProviders = this.getProvider(injector, LAZY_DSL_VALUES_PROVIDER, null);

            if (dslProviders?.length) {
                const lazyDslProviders = [];

                for (const provider of dslProviders) {
                    // @TODO: Fix typing
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    lazyDslProviders.push(provider);
                }

                if (lazyDslProviders.length) {
                    this.dslEnvService.addToContext(lazyDslProviders);
                }
            }
        } catch (e) {
            this.log.error('runDslProviders failed', injector, e);
        }
    }
}
