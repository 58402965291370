import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DslPipe, DynamicHtmlDirective, NativeAppService, UserService } from '@frontend/vanilla/core';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { VCClientService } from '@pokercore/module/core';
import { Autoplay, EffectCoverflow, Pagination, Virtual } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { ProjectXBannerResourceService } from './projectx-banner-resource.service';
import { StripHtmlPipe } from './projectx-banner.pipe';
import { ProjectXBannerResolver } from './projectx-banner.resolver';

//SwiperCore.use([Pagination, EffectCoverflow, Autoplay, Virtual]);
@Component({
    selector: 'pk-projectx-banner',
    templateUrl: 'projectx-banner.component.html',
    standalone: true,
    imports: [
        CommonModule,
        //SwiperModule,
        DslPipe,
        DynamicHtmlDirective,
        StripHtmlPipe,
        SwiperComponent,
    ],
    providers: [ProjectXBannerResourceService, ProjectXBannerResolver],
})
export class ProjectXBannerComponent implements OnInit {
    initData: any;
    content: any;
    promoData: any;
    sliderInterval: number;
    selectedBanners = Array(10).fill(false);
    theme: any;
    fontfamily: any;
    vcClient: any;
    pxCta: any;
    themeName: string;

    public config: SwiperOptions = {
        modules: [Pagination, EffectCoverflow, Autoplay, Virtual],
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        spaceBetween: 10,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        slidesPerGroup: 1,
        keyboard: true,
        rewind: true,
        observer: true,
    };

    @ViewChild('SwiperComponent') swiperSlider?: SwiperComponent;

    constructor(
        private routeData: RouteDataService,
        private nativeApp: NativeAppService,
        private route: ActivatedRoute,
        private vcClientServce: VCClientService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.initData = this.routeData?.getInitData();
        this.promoData = this.initData?.promotion;
        this.nativeApp.sendToNative({
            eventName: 'promotionCount',
            parameters: {
                count: this.promoData?.length,
            },
        });

        this.content = this.initData?.content?.messages;
        this.theme = this.route?.snapshot?.queryParams?.theme ? this.route?.snapshot?.queryParams?.theme : 'default';
        if (this.theme == 'purple') {
            this.themeName = 'themePurple';
        }
        this.fontfamily = this.route?.snapshot?.queryParams?.fontfamily ? this.route?.snapshot?.queryParams?.fontfamily : 'Saira';
        this.sliderInterval = this.initData?.carouselSliderTimeSpan * 2000;

        this.config = Object.assign({}, this.config, {
            autoplay: {
                delay: this.sliderInterval,
                disableOnInteraction: false,
            },
            watchOverflow: true,
        });
        this.vcClient = this.vcClientServce.isClient();
    }

    viewMore(selectedItem: any) {
        event?.stopPropagation();
        this.selectedBanners[selectedItem] = true;
        this.swiperSlider?.swiper.autoplay.stop();
    }

    viewLess(selectedItem: any) {
        event?.stopPropagation();
        this.selectedBanners[selectedItem] = false;
        this.swiperSlider?.swiper.autoplay.start();
    }

    onSlideChange() {
        this.swiperSlider?.swiper.autoplay.start();
    }

    linkToAction(promoCta: any) {
        event?.stopPropagation();
        const clientEvent = promoCta.nativeEvent;
        this.pxCta = promoCta.pxCta;
        if (this.vcClient) {
            if (clientEvent) {
                this.nativeApp.sendToNative({ eventName: clientEvent, parameters: this.pxCta });
                this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
            } else {
                const sso = this.userService.ssoToken;
                window.open(promoCta.link + `?sessionKey=${sso}`);
            }
        }
    }

    stopPropagation(event: any) {
        event?.stopPropagation();
    }

    ngAfterViewInit() {
        this.nativeApp.sendToNative({ eventName: 'BannerLoadCompleted', parameters: {} });
    }
}
