import { Provider } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { runOnAppInit } from '@frontend/vanilla/core';

import { registerLoaderFn } from './loader.service';
import { LoadersBootstrapService } from './loaders-bootstrap.service';
import { VANILLA_LOADERS_MAP } from './loaders-map';

/**
 * Provides providers of loaders package.
 *
 * @stable
 */
export function provideLoaders(): Provider[] {
    const vanillaLoadersFns = Object.keys(VANILLA_LOADERS_MAP).map((loaderId) => {
        return registerLoaderFn({ id: loaderId, loaderFn: VANILLA_LOADERS_MAP[loaderId]! });
    });

    return [...vanillaLoadersFns, runOnAppInit(LoadersBootstrapService)];
}
