import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { DeviceService, Page } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class PokerDownloadDeactivateGuard implements CanActivate {
    countOne: boolean;
    downloadUrl: any;
    initData: any;
    constructor(
        private page: Page,
        private router: Router,
        private deviceService: DeviceService,
    ) {}

    canActivate(): Promise<boolean> | boolean {
        return new Promise((resolve) => {
            if (this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet) {
                window.location.href = `${this.page.homePage}/downloadapp?devicePixelRatio=${devicePixelRatio}`;
            } else {
                this.downloadUrl = this.page.homePage + '/downloadapp';
                const downloadFrame = document.createElement('iframe');
                downloadFrame.setAttribute('id', 'downloadiFrame');
                downloadFrame.setAttribute('src', this.downloadUrl);
                downloadFrame;
                document.body.appendChild(downloadFrame);
                downloadFrame.style.display = 'none';
                setTimeout(() => {
                    this.router.navigate([this.page.lang + '/download']);
                    resolve(false);
                }, 3000);
            }
        });
    }
}
