import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class ProjectXBannerResourceService {
    constructor(private api: ApiService) {}

    getInitValues(stage: any, type: any): Observable<any> {
        let pathType = 'projectxbanner';
        if (window.location.pathname.indexOf('projectxbannerwip') >= 0) {
            pathType = 'projectxbannerwip';
        }
        return this.api.get(`projectxbanner/Get`, { pathType: pathType, stage: stage, type: type });
    }
}
