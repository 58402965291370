import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { ProjectXBannerResourceService } from './projectx-banner-resource.service';

@Injectable({ providedIn: 'root' })
export class ProjectXBannerResolver implements Resolve<any> {
    constructor(private resource: ProjectXBannerResourceService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.resource.getInitValues(route.queryParams.stage, route.queryParams.type);
    }
}
