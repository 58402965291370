<div id="pk-projectx-banner-promotion">
    <vn-swiper #SwiperComponent [swiperOptions]="config" *ngIf="promoData?.length > 0" (slideChange)="onSlideChange()">
        <ng-template *ngFor="let promo of promoData | dsl | async; index as j" swiperSlide>
            <div
                class="swiper-slide"
                [ngStyle]="{ 'background-image': 'url(' + promo?.backgroundImage?.src + ')', 'font-family': fontfamily }"
                (click)="linkToAction(promo?.cta?.[0])">
                <div class="pk-projectx-slide-content" [ngClass]="{ 'pk-bottom-content': promo?.alignTextToBottom }">
                    <div class="pk-title line-clamp" vnDynamicHtml="{{ promo?.title }}"></div>
                    <button *ngIf="promo?.cta?.[0]?.text" [class]="themeName" class="pk-button btn" (click)="linkToAction(promo?.cta?.[0])">
                        {{ promo?.cta?.[0]?.text }}
                    </button>
                    <div *ngIf="!selectedBanners[j]; else showViewLess" class="pk-view" (click)="viewMore(j)">
                        {{ content?.ViewMore }}
                        <a class="theme-up"></a>
                    </div>
                    <ng-template #showViewLess>
                        <div class="pk-description" vnDynamicHtml="{{ promo?.description }}"></div>
                        <div class="pk-view" (click)="viewLess(j)">
                            {{ content?.viewLess }}
                            <a class="theme-down"></a>
                        </div>
                    </ng-template>
                </div>
                <div class="pk-projectx-actor-image" [ngClass]="{ 'pk-bottom-image': promo?.alignTextToBottom }">
                    <div class="category-image" *ngIf="promo?.actorImage?.src"><img [src]="promo?.actorImage?.src" /></div>
                    <div class="pk-projectx-label-logo brand-logo"></div>
                </div>
                <div class="pk-terms" (click)="stopPropagation(j)">
                    <div class="line-clamp" vnDynamicHtml="{{ promo?.termsAndConditions }}"></div>
                    <div
                        class="pk-terms-tooltip"
                        *ngIf="promo?.termsAndConditions?.length > 70"
                        vnDynamicHtml="{{ promo?.termsAndConditions }}"></div>
                </div>
            </div>
        </ng-template>
    </vn-swiper>
</div>
